import * as axios from "axios"
import {useDispatch} from "react-redux";
import {logout, setAccessToken, setAuthData} from "../redux/auth-reducer";
import {isTokenExpired} from "../utils/utils";
import jwtDecode from "jwt-decode";


const baseURL = "https://apitest.osbb.gerc.ua/api";
//const baseURL = "http://127.0.0.1:8000/api";

const client = (content_type) => {
    const axiosInstance = axios.create({
        baseURL,
        headers: {
            "Content-Type": content_type ? content_type : "application/json",
        },
    });
    axiosInstance.interceptors.request.use(async (req) => {

        const accessToken = localStorage.getItem('accessToken')
        if (accessToken && accessToken !== "undefined") {

            if (!isTokenExpired(jwtDecode(accessToken))){
                req.headers.Authorization = `Bearer ${accessToken}`;
                return req
            };

            const response = await AuthApi.refresh(localStorage.getItem('refreshToken'));

            const newAccessToken = response.access;
            localStorage.setItem("accessToken", newAccessToken);
            //const dispatch = useDispatch()
            //dispatch(setAccessToken(newAccessToken))
            req.headers.Authorization = `Bearer ${newAccessToken}`;
            return req;
        } else {
            localStorage.clear()
            let dispatch = useDispatch()
            dispatch(logout())
        }

    });

    axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
            const config = error.config
            if (error.response !== undefined && error.response.status === 401) {
                localStorage.clear()
                window.location = "/authentication/sign-in";
            }
            if (config !== undefined && config.url !== undefined && config.url.includes("/api/auth/refresh/")) {
                localStorage.clear()
                window.location = "/authentication/sign-in";
            }
            return error;
            //return Promise.reject(error);
        }
    );
    return axiosInstance;
}

export const ProfileApi = {
    getProfiles(offset = 0, limit = 100, slug, locationSearch) {
        if ( locationSearch ) {
            return client().get(`/osbb/profiles/${locationSearch}`)
            .then(response => response.data);
        }
        return client().get(`/osbb/profiles/?offset=${offset}&limit=${limit}&slug=${slug}`)
            .then(response => response.data);
    },

    getProfileTypes() {
        return client().get(`/osbb/profiletypes/`)
            .then(response => response.data);
    },

    getProfile(id) {
        return client().get(`/osbb/profiles/${id}/`)
            .then(response => response.data);
    },

    updateProfile(data) {
        return client().put(`/osbb/profiles/${data.id}/`, data
        ).then(response => response
        ).catch(error => {
                    return Promise.reject(error.response);
                });
    },

    createProfile(data) {
        console.log("*** createProfile data", data)
        return client().post(`/osbb/profiles/`, data
        ).then(response => response
        ).catch(error => {
                    return Promise.reject(error.response);
                });
    },

    sendAccount(id) {
        return client().post(`/osbb/profile/user/${id}/change_password/`
            ).then(response => response.data
            ).catch(error => {
                return Promise.reject(error.response);
            });
    },

    activate(id) {
        return client().post(`/osbb/profile/user/${id}/activate/`
        ).then(response => response.data
        ).catch(error => {
            return Promise.reject(error.response);
        });
    },

    deactivate(id) {
        return client().post(`/osbb/profile/user/${id}/deactivate/`
        ).then(response => response.data
        ).catch(error => {
            return Promise.reject(error.response);
        });
    },

    deleteProfile(id) {
        return client().post(`/osbb/profiles/${id}/delete/`
        ).then(response => response.data
        ).catch(error => {
            return Promise.reject(error.response);
        });
    },
}


export const AccountApi = {
    getAccounts(offset = 0, limit = 100, locationSearch) {
        if ( locationSearch ) {
            return client().get(`/osbb/accounts/${locationSearch}`)
            .then(response => response.data);
        }
        return client().get(`/osbb/accounts/?offset=${offset}&limit=${limit}`)
            .then(response => response.data);
    },

    getAccount(id) {
        return client().get(`/osbb/accounts/${id}/`)
            .then(response => response.data);
    },

    getAccountProfiles(id) {
        return client().get(`/osbb/account/profiles/${id}/`)
            .then(response => response.data);
    },

    getAccountServices( id, balancedate) {
        let offset = 0, limit = 100
        if ( balancedate ) {
            return client().get(`/osbb/account/services/${id}/?balancedate=${balancedate}&offset=${offset}&limit=${limit}`)
            .then(response => response.data);
        }
        return client().get(`/osbb/account/services/${id}/`)
            .then(response => response.data);
    },

    getAccountAgreements(id) {
        return client().get(`/osbb/account/agreements/${id}/`)
            .then(response => response.data);
    },

    getDebtors(offset = 0, limit = 100, debt_sum = 10000, balancedate) {
        return client().get(`/osbb/debtors/?balancedate=${balancedate}&debt_sum=${debt_sum}&offset=${offset}&limit=${limit}`)
            .then(response => response.data);
    },

    getDebtorsSummary(debt_sum = 10000, balancedate) {
        return client().get(`/osbb/debtors1/?balancedate=${balancedate}&debt_sum=${debt_sum}`)
            .then(response => response.data);
    },
}

export const PaymentsApi = {
     get_paymentshistory(offset = 0, limit = 100) {
        return client().get(`/osbb/paymentshistory/?offset=${offset}&limit=${limit}`)
            .then(response => response.data);
    },

    create_payment(paymentdata) {
        return client().post(`/payments/create/`, paymentdata )
            .then(response => response.data);
    },

}

export const OsbbApi = {
    get() {
        return client().get(`/osbb/osbb/`)
            .then(response => response.data);
    },
    updateOsbbInfo(data) {
        return client().put(`/osbb/osbb2/${data.id}/`, data
        ).then(response => response
        ).catch(error => {
                    return Promise.reject(error.response);
                });
    },

    get_positions() {
        return client().get(`/osbb/positions/`)
            .then(response => response.data);
    },
    updatePositions(data) {
        return client().put(`/osbb/positionsedit/${data.id}/`, data
        ).then(response => response
        ).catch(error => {
                    return Promise.reject(error.response);
                });
    },
    createPositions(data) {
        return client().post(`/osbb/positionsedit/`, data
        ).then(response => response
        ).catch(error => {
                    return Promise.reject(error.response);
                });
    },
    deletePositions(id) {
        return client().delete(`/osbb/positionsedit/${id}/`
        ).then(response => response.data
        ).catch(error => {
            console.error('There was an error!', error);
        });
    },
    get_stats(user) {
        let offset = 0, limit = 100
        if (user) {
            return client().get(`/osbb/stats/?user=${user}&offset=${offset}&limit=${limit}`)
            .then(response => response.data);
        }
        return client().get(`/osbb/stats/?offset=${offset}&limit=${limit}`)
            .then(response => response.data);
    },
    get_servicestats(offset = 0, limit = 100,  balancedate, user) {
        if (user) {
            return client().get(`/osbb/servicestats/?user=${user}&balancedate=${balancedate}&offset=${offset}&limit=${limit}`)
            .then(response => response.data);
        }
        return client().get(`/osbb/servicestats/?balancedate=${balancedate}&offset=${offset}&limit=${limit}`)
            .then(response => response.data);
    },

    get_UsefullInfo() {
        return client().get(`/osbb/usefullinfo/`)
            .then(response => response.data);
    },
    updateUsefullInfo(data) {
        return client().put(`/osbb/usefullinfo/${data.id}/`, data
        ).then(response => response
        ).catch(error => {
                    return Promise.reject(error.response);
                });
    },
    createUsefullInfo(data) {
        return client().post(`/osbb/usefullinfo/`, data
        ).then(response => response
        ).catch(error => {
                    return Promise.reject(error.response);
                });
    },
    deleteUsefullInfo(id) {
        return client().delete(`/osbb/usefullinfo/${id}/`
        ).then(response => response.data
        ).catch(error => {
            console.error('There was an error!', error);
        });
    },
    // osbb rates
    getRates(all) {
        let offset = 0, limit = 100
        if (all) {
            return client().get(`/osbb/rates/?offset=${offset}&limit=${limit}`)
            .then(response => response.data);
        }
        return client().get(`/osbb/rates/?active=1`)
        .then(response => response.data);
    },
    deleteRates(id) {
        return client().delete(`/osbb/rate/${id}/`
        ).then(response => response.data
        ).catch(error => {
            console.error('There was an error!', error);
        });
    },
    createRates(data) {
        return client().post(`/osbb/rate/`, data
        ).then(response => response.data
        ).catch(error => {
            console.error('There was an error!', error);
        });
    },
    updateRates(data) {
        return client().put(`/osbb/rate/${data.id}/`, data ).then(response => response.data);
    },
    // osbb docs
    get_OsbbDocs(doc_type, data, fileName) {
        let params = '?doc_type=' + doc_type
        if (data){
            params = params + '&data=' + data
        }
        if (fileName){
            params = params + '&filename=' + fileName
        }
        return client().get(`/osbb/files/`+params)
            .then(response => response.data);
    },
    updateOsbbDoc(data) {
        return client().put(`osbb/files/${data.id}/`, data
        ).then(response => response
        ).catch(error => {
                    return Promise.reject(error.response);
                });
    },
    createOsbbDoc(data) {
        return client("multipart/form-data").post(`osbb/files/`, data
        ).then(response => response
        ).catch(error => {
                    return Promise.reject(error.response);
                });
    },
    deleteOsbbDoc(id) {
        return client().delete(`osbb/files/${id}/`
        ).then(response => response.data
        ).catch(error => {
            console.error('There was an error!', error);
        });
    },
}

export const HouseApi = {
    getHouses(offset = 0, limit = 100) {
        return client().get(`/osbb/houses/?offset=${offset}&limit=${limit}`)
            .then(response => response.data);
    },

    getHouse(id) {
        return client().get(`/osbb/houses/${id}/`)
            .then(response => response.data);
    },
    updateHouse(data) {
        return client().put(`/osbb/houses/${data.id}/`, data
        ).then(response => response
        ).catch(error => {
                    return Promise.reject(error.response);
                });
    },
    get_HouseAddInfo(id) {
        return client().get(`/osbb/houseaddinfos/${id}/`)
            .then(response => response.data);
    },
    updateHouseAddInfo(data) {
        return client().put(`/osbb/houseaddinfo/${data.id}/`, data
        ).then(response => response
        ).catch(error => {
                    return Promise.reject(error.response);
                });
    },
    createHouseAddInfo(data) {
        return client().post(`/osbb/houseaddinfo/`, data
        ).then(response => response
        ).catch(error => {
                    return Promise.reject(error.response);
                });
    },
    deleteHouseAddInfo(id) {
        return client().delete(`/osbb/houseaddinfo/${id}/`
        ).then(response => response.data
        ).catch(error => {
            console.error('There was an error!', error);
        });
    },

    getHouseMetters(id) {
        return client().get(`/osbb/house/meters/${id}/`)
            .then(response => response.data);
    },

    updateHouseMeter(data) {
        return client().put(`/osbb/house/meter/${data.id}/`, data
        ).then(response => response
        ).catch(error => {
                    return Promise.reject(error.response);
                });
    },
    createHouseMeter(data) {
        return client().post(`/osbb/house/meter/`, data
        ).then(response => response
        ).catch(error => {
                    return Promise.reject(error.response);
                });
    },
    deleteHouseMeter(house,id) {
        return client().delete(`/osbb/house/meter/${id}/`
        ).then(response => response.data
        ).catch(error => {
            console.error('There was an error!', error);
        });
    },

}

export const FlatApi = {
    getFlats(offset = 0, limit = 100, locationSearch) {
        if ( locationSearch ) {
            return client().get(`/osbb/flats/${locationSearch}`)
            .then(response => response.data);
        }
        return client().get(`/osbb/flats/?offset=${offset}&limit=${limit}`)
            .then(response => response.data);
    },

    getFlat(id) {
        return client().get(`/osbb/flats/${id}/`)
            .then(response => response.data);
    },

    getFlatMetters(id) {
        return client().get(`/osbb/flat/meters/${id}/`)
            .then(response => response.data);
    },

    getFlatMettersData(balancedate, balancedate2, servicetype, offset = 0, limit = 100, meter, locationSearch) {
        if (meter !== undefined) {
            return client().get(`/osbb/homemeterdata/?meter=${meter}&balancedate=${balancedate}&servicetype=${servicetype}&offset=${offset}&limit=${limit}`)
            .then(response => response.data);
        }
        if ( locationSearch ) {
            return client().get(`/osbb/homemeterdata/${locationSearch}&balancedate=${balancedate}&balancedate2=${balancedate2}&servicetype=${servicetype}&offset=${offset}&limit=${limit}`)
            .then(response => response.data);
        }
        return client().get(`/osbb/homemeterdata/?balancedate=${balancedate}&balancedate2=${balancedate2}&servicetype=${servicetype}&offset=${offset}&limit=${limit}`)
            .then(response => response.data);
    },

    getFlatMettersDataReport(balancedate, servicetype, offset = 0, limit = 1000) {
        return client().get(`/osbb/metersreport/?balancedate=${balancedate}&servicetype=${servicetype}&offset=${offset}&limit=${limit}`)
            .then(response => response.data);
    },

    sendMeterData(data, meterId) {

        return client("multipart/form-data").post(`osbb/meters/data/${meterId}/`, data
        ).then(response =>{
            console.log('*** then response', response );
            return response
        }
        ).catch(err => {
            console.error('*** There was an error!', err);
            if (err.response) {
                // client received an error response (5xx, 4xx)
                return Promise.reject(err.response);
              } else if (err.request) {
                // client never received a response, or request never left
                return Promise.reject(err.request);
              } else {
                // anything else
                console.log('Error', err.message);
                return Promise.reject(err)
              }

        });
    },

    getRecentMeterData(meterId) {
        return client().get(`osbb/meters/data/${meterId}/recent/`).then(response => response.data);
    },
}

export const AuthApi = {
   async getAccessToken() {
        const accessToken = localStorage.getItem('accessToken')
        if (accessToken === "undefined" || isTokenExpired(jwtDecode(accessToken))) {
            const refreshToken = localStorage.getItem('refreshToken')
            let response = await AuthApi.refresh(refreshToken)
            localStorage.setItem('accessToken', response.access)
            return response.access;
        }
        return accessToken
    },

    login(email, password, osbb) {
        let data = {
            email,
            password,
        }
        if (osbb) {
            data["osbb"] = osbb
        }
        return axios.post(`${baseURL}/auth/login/`, data);
    },

    register(email, password) {
        return axios.post(`${baseURL}/auth/register/`, {
            email,
            password,
        });
    },

    refresh(refresh) {
        return axios.post(`${baseURL}/auth/refresh/`, {
            refresh
        }).then(response => response.data);
    }
}

export const ChatMessagesApi = {
    getMessages(offset, limit, messageId, userId) {
        if (messageId > 0) {
            return client().get(`/chat/messages/?offset=${offset}&limit=${limit}&id__lt=${messageId}&userId=${userId}&ordering=-id`)
        } else {
            return client().get(`/chat/messages/?offset=${offset}&limit=${limit}&userId=${userId}&ordering=-id`)
        }
    }
}

export const PollsApi = {
    getPolls(all, active, completed) {
        if (all) {
            return client().get(`/polls/questions/?active=${active}&completed=${completed}`)
            .then(response => response.data);
        }
        return client().get(`/polls/questions/?active=true`)
            .then(response => response.data);
    },

    getPoll(id) {
        return client().get(`polls/questions/${id}/`)
            .then(response => response.data);
    },

    vote(question, choice) {
        return client().post(`/polls/questions/${question}/set_choice/`, {
            choice
        }).then(response => response.data);
    },

    createQuestion(data) {
        return client().post(`/polls/questions/`, data
        ).then(response => response.data
        ).catch(error => {
            console.error('There was an error!', error);
        });
    },

    updateQuestion(data) {
        return client().put(`/polls/questions/${data.id}/`, data ).then(response => response.data);
    },

    createQuestionChoice(data) {
        return client().post(`/polls/question/choices/`, data ).then(response => response.data);
    },

    updateQuestionChoice(choice, data) {
        return client().put(`/polls/question/choices/${choice}/`, data ).then(response => response.data);
    },

    deleteQuestionChoice(id) {
        console.log('*** deleteQuestionChoice id ***', id)
        return client().delete(`/polls/question/choices/${id}/`
        ).then(response => response.data
        ).catch(error => {
            console.error('There was an error!', error);
        });
    },

    deleteQuestion(id) {
        console.log('*** deleteQuestion id ***', id)
        return client().delete(`/polls/questions/${id}/`
        ).then(response => response.data
        ).catch(error => {
            console.error('There was an error!', error);
        });
    },

    sendNotification(id) {
        return client().post(`/polls/questions/${id}/send_notification/`
            ).then(response => response.data
            ).catch(error => {
                return Promise.reject(error.response);
            });
    },


}

export const FinanceApi = {
    getUnit(offset = 0, limit = 1000) {
        return client().get(`/finance/units/?offset=${offset}&limit=${limit}`)
        .then(response => response.data);
    },

    getService(offset = 0, limit = 1000) {
        return client().get(`/finance/services/?offset=${offset}&limit=${limit}`)
        .then(response => response.data);
    },

    getServiceType(offset = 0, limit = 1000) {
        return client().get(`/finance/servicetypes/?offset=${offset}&limit=${limit}`)
        .then(response => response.data);
    }
}

export const DeviceApi = {
    getDevice(offset = 0, limit = 1000) {
        return client().get(`/device_core/device/?offset=${offset}&limit=${limit}`)
        .then(response => response.data);
    },

}

export const OsbbSummaryApi = {
    getSummary(balancedate, accountid) {
        if ( accountid ) {
            return client().get(`/osbb/summary/?balancedate=${balancedate}&&account=${accountid}`)
                    .then(response => response.data);
        }

        return client().get(`/osbb/summary/?balancedate=${balancedate}`)
            .then(response => response.data);
    },

    get_serviceSummary(balancedate) {
        let offset = 0, limit = 100
        return client().get(`osbb/service/summary/?balancedate=${balancedate}&offset=${offset}&limit=${limit}`)
            .then(response => response.data);
    },

    get_userSummary(balancedate) {
        return client().get(`osbb/user/summary/?balancedate=${balancedate}`)
            .then(response => response.data);
    },

}
